<script>
import { defineComponent } from 'vue';
import useWebsiteTexts from '@/composables/useWebsiteTexts';

export default defineComponent({
  props: {
    selectedVariant: {
      type: Object,
      default: {}
    },
    title: {
      type: String,
      default: ''
    },
    text: {
      type: String,
      default: ''
    },
    show: {
      type: Boolean,
      default: false
    }
  }
});
</script>

<template>
  <div v-if="show" class="product-info__div">
    <div class="product-info__title">
      {{ title }}
    </div>
    <div class="product-info__text">
      {{ text }}
    </div>
  </div>
</template>

<style>
.product-info__title {
  margin-left: 1rem;
  font-weight: bold;
  font-size: 15px;
}

.product-info__text {
  margin: 0.5rem 1rem;
  font-size: 14px;
}
</style>
