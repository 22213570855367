<script>
import { computed, defineComponent } from 'vue';
import { gql } from 'graphql-request';
import { batch } from '@motillo/drapejs-litium';

import Layout from './components/Layout.vue';

export default defineComponent({
  name: 'App',
  components: {
    Layout
  },
  data() {
    return {
      cart: {
        data: {}
      },
      layout: {
        data: {}
      },
      channel: {
        data: {}
      },
      availableChannels: {
        data: []
      },
      categoryTree: {
        data: {}
      },
      user: {
        data: {}
      }
    };
  },
  provide() {
    return {
      $cartReactive: computed(() => this.cart),
      $channelReactive: computed(() => this.channel),
      $availableChannelsReactive: computed(() => this.availableChannels),
      $userReactive: computed(() => this.user)
    };
  },
  async serverPrefetch() {
    await Promise.all(this.scheduleQueries());
  },
  async created() {
    this.$subscribe('__cart', (data) => {
      this.cart.data = data;
    });

    this.$subscribe('__channel', (data) => {
      this.channel.data = data;
    });

    this.$subscribe('__availableChannels', (data) => {
      this.availableChannels.data = data;
    });

    this.$subscribe('__user', (data) => {
      this.user.data = data;
    });

    this.$subscribe('__litium', (data) => {
      this.user.isAuthenticated = data?.uid && data?.uid != 'NotAuthenticated';
    });

    const litium = await this.$getItem('__litium');
    this.user.isAuthenticated =
      litium?.uid && litium?.uid != 'NotAuthenticated';

    this.user.data = await this.$getItem('__user');

    this.cart.data = await this.$getItem('__cart');
    this.channel.data = await this.$getItem('__channel');
    this.availableChannels.data = await this.$getItem('__availableChannels');
    this.user.data = await this.$getItem('__user');
  },
  async mounted() {
    this.scheduleClientQueries();
  },
  methods: {
    scheduleQueries() {
      return [
        this.$invoke(batch.scheduleQuery, {
          query: gql`
            channels{
              systemId
              name
              url
              current
              countryId
              currencySymbol
              locale
            }
          `,
          cacheKey: '__availableChannels'
        }),
        this.$invoke(batch.scheduleQuery, {
          query: gql`
            channel {
              hidePricesForAnonymousUsers
              combineGiftCardPriceWithShipping
              website {
                websiteTexts(prefix:[
                  "global", 
                  "cart", 
                  "blog", 
                  "checkout", 
                  "pdp", 
                  "login", 
                  "header", 
                  "facets", 
                  "category", 
                  "search", 
                  "footer", 
                  "productdetailspage",
                  "common", 
                  "orderdetails", 
                  "mypages", 
                  "giftcard", 
                  "personalization",
                  "decoration",
                  "configurator",
                  "product_overview",
                ])
                fields: websiteFields
                systemId
              }
              newsCategory {
                id
                url
              }
              productCategoriesRoot {              
                callToAction
                id
                name
                url
                subCategories {
                  id
                  name
                  imageId
                  url
                }
              }
              headerCategories {
                callToAction
                showCircleImages
                name
                url
                subCategories {
                  id
                  name
                  imageId
                  url
                }
              }
              headerMenuLinks {
                id
                label
                labelPhoneOverride
                url
                menuBlocksJson
              }
              headerCategoryListingLinks {
                id
                label
                labelPhoneOverride
                url
                menuBlocksJson
              }
              frameworkContentJson
              rootPath
              locale
              language
              orderDetailsPageUrl
              loginPageUrl
              techSpecFields {
                id
                name
                type
              }
              certificationFields {
                id
                name
                type
              }
              sizes {
                name
                value
              }
              recipientCountries {
                name
                id
                priority
              }
              systemId
            }
          `,
          cacheKey: '__channel'
        })
      ];
    },
    scheduleClientQueries() {
      return [
        ...this.scheduleQueries(),
        this.$invoke(batch.scheduleQuery, {
          query: gql`
            user {
              isAuthenticated
              companyName
              canRepresentAnyOrganization
              organization {
                organizationName
                organizationId
                navCustomerNumber
                organizationNo
                name
                address
                address2
                postCode
                city
                country
                currency
                discountGroup
                countries {
                  value
                  text
                }
                deliveryCountries {
                  value
                  text
                }
                shippingAddresses {
                  no
                  code
                  name
                  address
                  address2
                  postCode
                  city
                  country
                }
                contacts {
                  no
                  code
                  firstName
                  lastName
                  email
                  phone
                }
              }
              organizations {
                  organizationName
                organizationId
              }
              person {
                  firstName
                  lastName
                  email
                  emailUnverified
                  phone
              }
            }
          `,
          cacheKey: '__user'
        })
      ];
    }
  }
});
</script>

<template>
  <router-view>
    <layout />
  </router-view>
</template>
